import React from 'react';
import Layout from '../layout';
import { StaticImage } from 'gatsby-plugin-image';

function NotFound() {
  return (
    <Layout>
      <section className="legacy">
        <div className="container">
          <div className="row">
            <div className="col-md-4 content">
              <h1>Psssst!</h1>
              <p>
                Whoops. You shouldn't be here.
                <br /> Can you please wait at: <br />
                <a href="/">home page</a> &nbsp; <a href="/blog">blog</a>
              </p>
            </div>
            <div className="col-md-6 col-md-offset-1 text-center content">
              <div id="404svg">
                <StaticImage src={`../../static/images/404-sleeping.svg`} alt={``} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default NotFound;
